<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <!-- <pre>{{ form }}</pre> -->
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <CCol sm="6">
          <CInput v-model="form[`name`]" :label="`Name`" :placeholder="`Name`" />
        </CCol>

        <CCol sm="6">
          <CInput v-model="form.product_id" label="Product id" placeholder="Product id" />
        </CCol>

        <CCol sm="6">
          <CInput
            v-model="form.count_download"
            label="Count download"
            placeholder="Count download"
          />
        </CCol>

        <CCol sm="6">
          <CInput v-model="form.created_at" label="Created At" placeholder="Created At" />
        </CCol>

        <CCol sm="6">
          <MultiselectServerSearch
            v-model="form.tags"
            label="Tags"
            placeholder="Tags"
            :data="tagOptions"
          />
          <!-- <AppSlect
            v-model="form.tags"
            :options="tagOptions"
            multiple
            label="Tag"
          />-->
        </CCol>

        <CCol sm="2">
          <CInput v-model="form.order" label="Order" placeholder="Order" />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Show"
            :checked="form.show"
            @update:checked="value => (form.show = value)"
          />
        </CCol>

        <!-- <CCol sm="12">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.image && form.image.file"
            name="image"
            :image="form.image && form.image.url ? form.image.url : form.image"
            @input="value => handleChangeFile({ value, key: 'image' })"
          />
        </CCol>-->
      </CRow>

      <CRow>
        <CCol sm="12">
          <ModalPresets
            :isShow="isShow"
            :data="form.presets"
            @change="value => (form.presets = value)"
            @delete="deletePreset"
          />
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import MixinsScreenModal from "@/mixins/screenModal";

import ModalPresets from "./ModalPresets";

import FileLoader from "@/components/root/FileLoader";

import MultiselectServerSearch from "@/components/root/MultiselectServerSearch";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {
    ModalPresets,
    FileLoader,

    MultiselectServerSearch
  },

  data: () => ({
    form: {
      name: null,
      product_id: null,
      count_download: 0,
      presets: [],
      show: true,
      created_at: null,
      // image: null,
      tags: null,
      order: 0
    },

    languages: ["en", "ru", "fr", "es", "de", "it"]
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data
          ? "Edit Instapano collection"
          : "Add Instapano collection";
      }
    },

    createdAt() {
      // return this.form[`created_at`];
      // return moment(this.form[`created_at`])
      //   .subtract("hours", 3)
      //   .format("YYYY-MM-DD hh:mm:ss");
    },

    tagOptions() {
      let res = [];

      for (let item of this.addictionsData.tag) {
        res.push({ value: item.id, label: item.name });
      }

      return res;
    }
  },

  methods: {
    handleChangeFile({ value, key }) {
      console.log("handleChangeFile");

      this.form[key] = {
        type: "upload",
        file: value,
        url: URL.createObjectURL(value)
      };

      // this.form[key].image = URL.createObjectURL(file);

      this.$emit("change", this.form);
    },

    deletePreset(index) {
      console.log(index);

      if (this.form.presets[index] && this.form.presets[index].id)
        this.handlerDeletePreset({
          id: this.form.presets[index].id,
          cb: () => {
            this.form.presets.splice(index, 1);
          }
        });
      else this.form.presets.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.presetio-modals-modificated {
  &__preset {
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid #768192;
  }
}
</style>
