<template>
  <div class="modal-presets">
    <!-- <pre>{{ form }}</pre> -->

    <i class="el-icon-close modal-presets__icon" @click="$emit('delete', index)"></i>

    <div class="modal-presets__item" v-for="(value, key, index) of form" :key="index">
      <!-- <FileLoaderUrl
        style="min-width: 250px"
        v-if="renderItems.indexOf(key) != -1 && isShow"
        :value="form[key] && form[key].type ? form[key].file : form[key]"
        :name="key"
        :index="index"
        :isShow="isShow"
        @input="value => handleChangeFile(value, key)"
      />-->
      <FileLoader
        v-if="renderItems.indexOf(key) != -1 && isShow"
        :value="form[key] && form[key].file"
        :name="key"
        :type="key == 'preset' || key == 'video' ? 'file' : 'image'"
        :image="value && value.url ? value.url : value"
        :index="index"
        :isShow="isShow"
        accept="*"
        @input="value => handleChangeFile(value, key)"
      />
    </div>

    <CRow>
      <CCol sm="4">
        <CInput
          v-model="form.name"
          label="Name"
          placeholder="Name"
          @input="value => handleChange({ value, key: 'name' })"
        />
      </CCol>

      <CCol sm="3" style="margin-top: 29px">
        <CInputCheckbox
          class="modal-presets__checkbox"
          label="Free"
          :checked="form.free"
          @update:checked="value => handleChange({ value, key: 'free' })"
        />

        <CInputCheckbox
          class="modal-presets__checkbox"
          label="Show"
          :checked="form.show"
          @update:checked="value => handleChange({ value, key: 'show' })"
        />
      </CCol>

      <CCol sm="3" style="margin-top: 29px">
        <CInput
          :value="form.order"
          @input="value => handleChange({ value, key: 'order' })"
          placeholder="Order"
        />
      </CCol>

      <CCol sm="2" style="margin-top: 29px" v-if="form.id">
        <CButton color="success" @click="handleOpenEditor">Editor</CButton>
      </CCol>
    </CRow>

    <ModalImageEnlargement
      v-if="showModalImageEnlargement"
      :image="selectedImage"
      @close="showModalImageEnlargement = false"
    />
  </div>
</template>

<script>
import FileLoader from "@/components/root/FileLoader";
import FileLoaderUrl from "@/components/root/FileLoaderUrl";

import axios from "axios";
import Vue from "vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

import ModalImageEnlargement from "@/components/modals/ModalImageEnlargement";

export default {
  name: "modal-presets",

  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },

    index: {
      type: [String, Number],
      default: 0
    },

    isShow: {
      type: Boolean,
      default: true
    }
  },

  components: {
    FileLoader,
    FileLoaderUrl,

    ModalImageEnlargement
  },

  data: () => ({
    showModalImageEnlargement: false,
    selectedImage: null,

    renderItems: ["image"],

    form: {
      image: null,
      free: false,
      show: false,
      order: 0
    },

    imageResize: null,
    showModalResize: false

    // formChange: {
    //   file1: null,
    //   file2: null,
    //   file3: null,
    //   file4: null,
    //   file5: null
    // },
  }),

  // computed: {
  //   ...mapGetters({
  //     token: "token"
  //   })
  // },

  watch: {
    // isShow(value) {
    //   if (!value) {
    //     this.form = {
    //       reference: null,
    //       image: null,
    //       preset: null
    //     };
    //   }
    // },

    data(value) {
      console.log("chanfeData", value);

      this.form = value;
    }
  },

  created() {
    this.form = this.data;
  },

  methods: {
    handleChangeFile(file, key) {
      console.log("handleChangeFile");

      if (!key) key = this.activeImage.key;

      this.form[key] = {
        type: "upload",
        file,
        url: URL.createObjectURL(file)
      };

      // this.form[key].image = URL.createObjectURL(file);

      this.$emit("change", this.form);
    },

    handleChange({ value, key }) {
      this.form[key] = value;

      this.$emit("change", this.form);
    },

    handlerLoadFile() {
      var file = this.$refs.file.files[0];

      if (file) {
        console.log(file);

        this.handleChangeFile(file);

        // this.link = URL.createObjectURL(file);
        // this.$emit("input", file);
      }
    },

    handleUpdate({ id, index }) {
      this.activeImage = {
        key: `file${index + 1}`
      };

      this.$refs.file.click();
    },

    handleShowImage(image) {
      this.showModalImageEnlargement = true;
      this.selectedImage = image;
    },

    handleOpenEditor() {
      let token = Vue.$cookies.get("user-token");

      window.open(
        `https://creator-story.unocreative.studio/${this.data.id}?token=${token}`,
        "_blank"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-presets {
  margin-top: 1rem;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;

  position: relative;

  border: 1px solid #768192;

  &__icon {
    position: absolute;
    right: 10px;
    margin-top: 5px;

    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
      transition: all 0.3s;
    }
  }

  &__checkbox {
    width: 100%;
  }
}
</style>
